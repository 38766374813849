import { useEffect, useState } from 'react'
/*global state management with redux*/
import { useAppSelector, useAppDispatch } from '../../app/hooks'

import {
    contentConfigFileToGlobalStateAsync,
    selectActivatedLanguage,
    selectLanguageSupportOverAll,
    selectStatusLanguageSupportContent,
    selectLanguageSupportState,
} from '../languageSupport/languageSupport.Slice'

import {
    RemoveErrors,
    AddError,
    AddAnswer,
    AddMoreFields,
    AddFiles,
    AddCheckboxesInCheckbox,
    AddQuestionsFromLanguageSupport,
    selectQuestionsFromLanguageSupport,
    selectExampleState,
    selectStepQuestions,
    RemoveAll,
    createNewCase,
    Confirmed,
    CaseClosed,
    questionsConfigFileToGlobalStateAsync,
} from './questions.Slice'
import { IQuestionObject, IStepperObject } from '../../app/types'
import { useNavigate, useOutletContext } from 'react-router-dom'

import UserInputs from './UserInputs'

//import { ExampleConfigStepper as stepsInDigitalService } from './questions.Stepper'

//import EditSupport from './EditSupport'

import {
    Stepper,
    StepperMobile,
    StepperButtons,
    ServiceHeadlineAndBody,
    TextHeadlineAndBody,
    TransitionForPages as TransitionForText,
} from 'ui-components-react-package-45ds546dksw34'
import { GetTokenHandler } from '../../app/ApiHandler'
import { stat } from 'fs'

const InputComponentLibrary = () => {
    const handleModal: any = useOutletContext()
    const [toggleErrorCheck, setToggleErrorCheck] = useState(false)
    const [forwardButtonIsClicked, setForwardButtonIsClicked] = useState(false)
    //const [steps, setSteps] = useState<any>([{}])
    const [activeStep, setActiveStep] = useState(1)
    const [scrollUp, setScrollUp] = useState(true)
    const [showPreview, setShowPreview] = useState(false)
    const [showConfigure, setShowConfigure] = useState(true)
    const [arrayOfErrors, setArrayOfErrors] = useState<any>([])
    const [endMessage, setEndMessage] = useState<any>({})
    const [contentLoaded, setContentLoaded] = useState(false)
    const [loading, setLoading] = useState(false)
    const [config, setConfig] = useState<any>({})
    const [steps, setSteps] = useState<IStepperObject[]>([])

    const [tokenFromApi, setTokenFromApi] = useState<string>('')

    const dispatch = useAppDispatch()
    const activatedLanguage: string = useAppSelector(selectActivatedLanguage)

    const questionsFromLanguageSupport: any = useAppSelector(state =>
        selectQuestionsFromLanguageSupport(state, activatedLanguage)
    )
    const languageSupported: any = useAppSelector(selectLanguageSupportOverAll)
    const statusLanguageSupportedContent = useAppSelector(selectStatusLanguageSupportContent)
    const languageSupportLoaded = useAppSelector(selectLanguageSupportState).languageSupportLoaded

    const stepData: any = useAppSelector(state => selectStepQuestions(state, activeStep, steps.length))
    const status = useAppSelector(selectExampleState).status
    const saved = useAppSelector(selectExampleState).saved
    const questionsLoaded = useAppSelector(selectExampleState).questionsLoaded
    const data = useAppSelector(selectExampleState).data
    const editOngoing = useAppSelector(selectExampleState).editOngoing
    const reviewedAndConfirmed = useAppSelector(selectExampleState).confirmed
    const shutDown = useAppSelector(selectExampleState).caseClosed
    const stepsInDigitalService = useAppSelector(selectExampleState).stepper

    //fungerar men borde hämta ifrån config.json istället (fungerar just nu ej i produktion)
    // const contentConfigUrl = 'https://utbyggnadsportalen.pts.se'
    // const contentConfigFile = 'content.dsa.languageSupport.json'
    // const questionsConfigUrl = 'https://utbyggnadsportalen.pts.se'
    // const questionsConfigFile = 'content.dsa.questions.json'

    useEffect(() => {
        shutDown && dispatch(RemoveAll())
        shutDown && setActiveStep(0)
    }, [shutDown])

    useEffect(() => {
        stepsInDigitalService && setSteps(stepsInDigitalService)
    }, [stepsInDigitalService])

    //Add questions related config from selected language to global state
    useEffect(() => {
        activatedLanguage && contentLoaded && dispatch(AddQuestionsFromLanguageSupport(questionsFromLanguageSupport))
    }, [contentLoaded])

    //1. Get config from json file
    useEffect(() => {
        fetch('/config.json')
            .then(response => response.json())
            .then(data => setConfig(data))
    }, [])

    //2. Get token from api
    useEffect(() => {
        if (config && config.getTokenUrl) {
            const response = GetTokenHandler({ AccessHeader: config.AccessHeader, getTokenUrl: config.getTokenUrl })
            response.then((data: any) => {
                //data && alert('data: ' + JSON.stringify(data))
                if (data.result === 'error') {
                    errorModal({
                        headline: '',
                        body: 'Du kommer ej kunna skicka in. Felmeddelande: Ingen kontakt med server (hittar ej token). Kontakta support.',
                        statusCode: data.status,
                    })
                } else {
                    //data && alert('token: ' + data)
                    data && setTokenFromApi(data)
                }
            })
        }
    }, [config])

    //3. Set content json file to global state
    useEffect(() => {
        // console.log('0. accessheader index: ' + config.AccessHeader + ' ' + config.getTokenUrl)

        // console.log('0. contentURL index: ' + config.contentConfigUrl + ' ' + config.contentConfigFile)

        // config.contentConfigUrl &&
        //     console.log('0.5 contentURL index: ' + config.contentConfigUrl + ' ' + config.contentConfigFile)

        // config &&
        //     config.contentConfigFile &&
        //     console.log('1. contentURL index: ' + config.contentConfigUrl + ' ' + config.contentConfigFile)

        // fungerar ej i produktion. Hämtar ej ifrån config.json (förstår ej varför). Fungerar i utveckling.
        !languageSupportLoaded &&
            statusLanguageSupportedContent !== 'failed' &&
            config.contentConfigUrl &&
            config.contentConfigFile &&
            dispatch(
                contentConfigFileToGlobalStateAsync({
                    url: config.contentConfigUrl,
                    fileName: config.contentConfigFile,
                })
            )
        !questionsLoaded &&
            config.questionsConfigUrl &&
            config.questionsConfigFile &&
            dispatch(
                questionsConfigFileToGlobalStateAsync({
                    url: config.questionsConfigUrl,
                    fileName: config.questionsConfigFile,
                })
            )

        // !languageSupportLoaded &&
        //     statusLanguageSupportedContent !== 'failed' &&
        //     contentConfigUrl &&
        //     contentConfigFile &&
        //     dispatch(
        //         contentConfigFileToGlobalStateAsync({
        //             url: contentConfigUrl,
        //             fileName: contentConfigFile,
        //         })
        //     )
        // !questionsLoaded &&
        //     questionsConfigUrl &&
        //     questionsConfigFile &&
        //     dispatch(
        //         questionsConfigFileToGlobalStateAsync({
        //             url: questionsConfigUrl,
        //             fileName: questionsConfigFile,
        //         })
        //     )

        //config && setContentLoaded(true)
    }, [config])

    //4. Handle failed to fetch content from config file
    useEffect(() => {
        statusLanguageSupportedContent === 'failed' &&
            errorModal({
                headline: '',
                body: 'Kan ej hämta innehåll. Felmeddelande: Ingen kontakt med server (hittar ej contentConfig). Kontakta support.',
                statusCode: '',
            })
    }, [statusLanguageSupportedContent])

    //5. Handle failed to fetch questions from config file
    useEffect(() => {
        status === 'failed' &&
            questionsLoaded === false &&
            errorModal({
                headline: '',
                body: 'Kan ej hämta innehåll. Felmeddelande: Ingen kontakt med server (hittar ej contentConfig). Kontakta support.',
                statusCode: '',
            })
    }, [status])

    //6. Set content loaded to true

    useEffect(() => {
        languageSupportLoaded && questionsLoaded && setContentLoaded(true)
    }, [statusLanguageSupportedContent, questionsLoaded, languageSupportLoaded])

    useEffect(() => {
        !editOngoing &&
            reviewedAndConfirmed &&
            dispatch(
                createNewCase({
                    data,
                    confirmed: reviewedAndConfirmed,
                    token: tokenFromApi,
                    postToServerUrl: config.postToServerUrl,
                    AccessHeader: config.AccessHeader,
                })
            )
    }, [reviewedAndConfirmed])

    useEffect(() => {
        setForwardButtonIsClicked(false)
        scrollUp && window.scrollTo(0, 0)

        //last step, before sending
        activeStep === steps.length && setShowPreview(true)
        activeStep === steps.length && setShowConfigure(false)

        //steps before last step
        activeStep !== steps.length && setShowPreview(false)
        activeStep !== steps.length && setShowConfigure(true)

        setToggleErrorCheck(!toggleErrorCheck)
    }, [activeStep])

    //Error check. Add error to global state if error exist. This is done from start. But is shown when forward button is clicked
    useEffect(() => {
        contentLoaded && ErrorCheck(stepData)
    }, [toggleErrorCheck, contentLoaded])

    //Status of api-call and handling of userSaveMode
    useEffect(() => {
        //alert('status: ' + status + ' saved: ' + saved)

        //Spinner and modal handling
        if (status === 'loading') {
            setLoading(true)
            if (reviewedAndConfirmed) {
                const modalText = languageSupported.supportedTextBlock.find(
                    (element: any) => element.TextBlock === 'ModalSubmitLoadingBlock'
                )
                handleModal({
                    title: modalText.headline,
                    content: modalText.body,
                    loading: true,
                })
            } else {
                //Todo: behövs bättre hantering av detta
                setTimeout(() => {
                    loading &&
                        handleModal({
                            title: 'Sparar...',
                            content: '',
                            loading: true,
                        })
                }, 1000)
            }
        } else {
            //Todo: behövs bättre hantering av detta, se ovan.
            setLoading(false)
            handleModal({
                Close: true,
            })
        }

        //Success and modal handling
        if (saved === 'success') {
            setTimeout(() => {
                setLoading(false)
                handleModal({
                    Close: true,
                })
            }, 1000)
            const endText = languageSupported.supportedTextBlock.find(
                (element: any) => element.TextBlock === 'ThankYouBlock'
            )
            setTimeout(() => {
                setEndMessage(endText)
            }, 1050)

            //shutdown
            reviewedAndConfirmed && dispatch(CaseClosed(true))
        }

        //Error and modal handling
        if (status === 'failed') {
            setLoading(false)
            const modalText = languageSupported.supportedTextBlock.find(
                (element: any) => element.TextBlock === 'ModalSubmitErrorBlock'
            )

            errorModal({
                headline: modalText ? modalText.headline : 'Fel vid inläsning',
                body: modalText ? modalText.body : 'Kontakta support.',
            })

            // handleModal({
            //     title: modalText.headline,
            //     content: modalText.body,
            // })
        }
        //Error and modal handling
        if (saved === 'error') {
            setLoading(false)
            const modalText = languageSupported.supportedTextBlock.find(
                (element: any) => element.TextBlock === 'ModalSubmitErrorBlock'
            )

            errorModal({
                headline: modalText ? modalText.headline : 'Fel vid inläsning',
                body: modalText ? modalText.body : 'Kontakta support.',
            })

            // handleModal({
            //     title: modalText.headline,
            //     content: modalText.body,
            // })
        }
    }, [status, saved])

    const errorModal = ({ headline = '', body = '', statusCode = '' }: any) => {
        const modalText = languageSupported.supportedTextBlock.find(
            (element: any) => element.TextBlock === 'ModalSubmitErrorBlock'
        )
        const status = statusCode && statusCode
        const title = headline ? headline : modalText ? modalText.headline : 'Fel vid inläsning'
        const content = body ? body : modalText ? modalText.body + ' (' + status + ')' : 'Kontakta support.'

        handleModal({
            title,
            content,
            loading: true,
        })
    }

    //ToDo: useCallback hook instead ?
    const ErrorCheck = (arrayOfQuestionObjects: any) => {
        let arrErrors: any = []
        arrayOfQuestionObjects.forEach((questionObject: IQuestionObject) => {
            if (ErrorCheckQuestionObject(questionObject, arrayOfQuestionObjects)) {
                questionObject.Id && dispatch(AddError({ Id: questionObject.Id }))

                arrErrors = [...arrErrors, questionObject]
            }
        })
        setArrayOfErrors(arrErrors)
    }

    const changeStepHandler = (step: any) => {
        setScrollUp(false)
        setActiveStep(step)
    }

    //ToDo: useCallback hook instead ?
    const updateInputElement = (e: any, questionObject: any) => {
        //reset all errors
        dispatch(RemoveErrors(stepData))
        //update specific input element
        dispatch(AddAnswer({ Id: questionObject.Id, Answer: e.target.value }))
        questionObject.QuestionType === 'AddMoreFields' &&
            dispatch(AddMoreFields({ Id: questionObject.Id, MoreFields: e.target.MoreFields }))
        questionObject.QuestionType === 'AddFiles' &&
            dispatch(AddFiles({ Id: questionObject.Id, Files: e.target.files }))
        questionObject.QuestionType === 'CheckboxesInCheckbox' &&
            e.target.questionArray &&
            dispatch(AddCheckboxesInCheckbox({ Id: questionObject.Id, questionArray: e.target.questionArray }))
        //error check again (after render) and set errors if exist
        setToggleErrorCheck(!toggleErrorCheck)
    }

    const inputSubmissionHandler = () => {
        //setDataSubmit(true)
        //setSubmitData(true)
    }

    const reviewedAndConfirmedHandler = () => {
        dispatch(Confirmed(true))
    }

    const forwardButtonHandler = (e: any) => {
        setForwardButtonIsClicked(true)
        arrayOfErrors.length === 0 && setActiveStep(activeStep + 1)
    }

    const StepperHeadlineText = activeStep > 0 ? steps.find((step: any) => step.Step === activeStep) : ''
    const Text = StepperHeadlineText && StepperHeadlineText.Text

    return (
        <>
            {contentLoaded && (
                <>
                    {data && data.length > 0 && (
                        <>
                            <Stepper activeStep={activeStep} arraySteps={steps} changeStepHandler={changeStepHandler}>
                                {/* {activeStep === 1 && ( */}
                                <ServiceHeadlineAndBody
                                    activeStep={activeStep}
                                    data={languageSupported}
                                    showBody={true}
                                    showLinksMoreInfo={true}
                                />
                                {/* )} */}
                            </Stepper>
                        </>
                    )}

                    {/* Special case...if not just hidden it will create a bug...because Iframe must exist if editSupport, when logging out... */}
                    <div style={data && data.length > 0 ? { display: 'block' } : { display: 'none' }}>
                        <UserInputs
                            headline={Text}
                            data={stepData}
                            forwardButtonHandler={forwardButtonHandler}
                            isTouched={updateInputElement}
                            //languageSupported={languageSupportOverall}
                            activatedLanguage={activatedLanguage}
                            //headline={languageSupportForFeature.headline}
                            errors={arrayOfErrors}
                            //errorExist={errorExist}
                            showErrors={forwardButtonIsClicked}
                            showPreview={showPreview}
                            showConfigure={showConfigure}
                            activeStep={activeStep}
                            arraySteps={steps}
                            changeStepHandler={changeStepHandler}
                        />

                        <StepperButtons
                            languageSupported={languageSupported}
                            handleActiveStep={changeStepHandler}
                            forwardButtonHandler={forwardButtonHandler}
                            inputSubmissionHandler={() => {
                                //setConfirmed(true)
                                //inputSubmissionHandler()

                                reviewedAndConfirmedHandler()
                            }}
                            activeStep={activeStep}
                            arraySteps={steps}
                        >
                            {/* <StepperMobile
                                activeStep={activeStep}
                                arraySteps={steps}
                                changeStepHandler={changeStepHandler}
                            /> */}
                            {/* <EditSupport accessInfo={{ config, tokenFromApi }} /> */}
                        </StepperButtons>
                    </div>
                    {data && data.length === 0 && endMessage.headline && (
                        <>
                            <TransitionForText>
                                <fieldset className="EndMessage">
                                    <TextHeadlineAndBody data={endMessage} />
                                </fieldset>
                            </TransitionForText>
                        </>
                    )}
                </>
            )}
        </>
    )
}

export default InputComponentLibrary

const isCategoryCheckboxChecked = (category: any, data: any) => {
    let categoryChecked: boolean = false
    //find all questions with same Category and QuestionType === 'CheckboxesInCheckbox'
    const categoryQuestions = data.filter(
        (questionObject: IQuestionObject) =>
            questionObject.Category === category && questionObject.QuestionType === 'CheckboxesInCheckbox'
    )
    //console.log('categoryQuestions ', categoryQuestions)
    //loop through all questions and check if any is checked
    categoryQuestions.map((questionObject: IQuestionObject) => {
        if (questionObject.Answer === 'true') {
            categoryChecked = true
        }
    })
    return categoryChecked
}

const ErrorCheckQuestionObject = (questionObject: any, arrayOfQuestionObjects: any) => {
    let error: boolean = false

    //ToDo: add more error types
    if (questionObject.Mandatory) {
        if (questionObject.isTouched === false) {
            error = true
        }
        // warning: trim() will not work if the value is not a string
        if (questionObject.Answer.trim() === '') {
            error = true
        }
        if (questionObject.Answer === 'false' && questionObject.errorType?.find((e: any) => e === 'mustBeTrue')) {
            error = true
        }
    }
    if (questionObject.CategoryMandatory) {
        if (
            !isCategoryCheckboxChecked(questionObject.Category, arrayOfQuestionObjects) &&
            questionObject.errorType?.find((e: any) => e === 'categoryCheckboxChecked')
        ) {
            error = true
        }
    }
    if (
        questionObject.errorType?.find((e: any) => e === 'email') &&
        (questionObject.Mandatory || questionObject.Answer !== '')
    ) {
        const emailRegex = /\S+@\S+\.\S+/
        if (!emailRegex.test(questionObject.Answer)) {
            error = true
        }
    }

    return error
}
