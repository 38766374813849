import { useEffect, useRef, useState } from 'react'

//branded components from our common npm package
import {
    Checkbox,
    Radio,
    TextField,
    TextArea,
    DatePickerInline as DatePicker,
    AddMoreFields,
    //AddFiles,
    AttachFiles as AddFiles,
    Dropdown,
    CustomDropdown,
    CheckboxesInCheckbox,
    HajkMapArea,
    Timer as CustomTimer,
    PrincipleOfPublicity,
    EditPreviewLink,
} from 'ui-components-react-package-45ds546dksw34'

//import AddFiles from '../../components/AttachFiles'

const UserInputs = ({
    data,
    isTouched,
    activatedLanguage,
    headline,
    errors,
    showErrors,
    arraySteps,
    changeStepHandler,
    showPreview = false,
    showConfigure = true,
    endMessage = {},
}: any) => {
    const errorRef = useRef<any>(null)
    const forwardButton = useRef<any>(null)
    const [nextCountDown, setNextCountDown] = useState(0)
    const [currentError, setCurrentError] = useState<any>(null)
    const [moveToFirstError, setMoveToFirstError] = useState(true)

    //console.log('UserInputs', data)

    useEffect(() => {
        //every time errors change, set first error to start timer
        showErrors && setCurrentError(errors && errors.length > 0 ? errors[0] : null)
    }, [errors])

    /* inactivated timer and move to next error */
    useEffect(() => {
        //showErrors && alert('moveToNextError')
        showErrors && moveToNextError()
    }, [showErrors])

    const moveToNextError = () => {
        if (errorRef.current !== null) {
            showErrors &&
                window.scrollTo({
                    top: errorRef.current.offsetTop - window.innerHeight / 2,
                    behavior: 'smooth',
                })
            //errorRef.current.input.focus()
            //errors && errors.length > 0 && setNextCountDown(errors[0].errorTimer ? errors[0].errorTimer : 0)
            return true
        } else {
            //If no errors, move to forward button
            showErrors &&
                window.scrollTo({
                    top: forwardButton.current.offsetTop,
                    behavior: 'smooth',
                })
            return false
            //forwardButton.current.focus()
        }
    }

    const sortQuestionsByCategory = (data: any) => {
        const sortedData = [...data] // clone array
        const groups = sortedData.reduce((acc, obj) => {
            const key = obj.Category
            const name = obj.Category
            const curGroup = acc[key] ?? []
            return { ...acc, [key]: [...curGroup, obj] }
        }, [])

        const array = Object.entries(groups).map(([key, value]) => {
            return { category: key, questions: value }
        })

        return array
    }

    const sortQuestionsBySteps = (data: any) => {
        //const sortedData = [...data] // clone array

        //filter out all rows that are dependent on answer
        const sortedData = [...data].filter((question: any) => filterOutDependentOnAnswer(question))

        const groups = sortedData.reduce((acc, obj) => {
            const key = obj.Step
            const name = obj.Step
            const curGroup = acc[key] ?? []
            return { ...acc, [key]: [...curGroup, obj] }
        }, [])

        const array = Object.entries(groups).map(([key, value]) => {
            return { step: key, questions: value }
        })

        return array
    }

    //special case for checkboxes in checkbox in preview when they are grouped by groupid
    const sortQuestionsByGroups = (data: any) => {
        const sortedData = [...data] // clone array
        const groups = sortedData.reduce((acc, obj) => {
            const key = obj.GroupId
            const name = obj.GroupId
            const curGroup = acc[key] ?? []
            return { ...acc, [key]: [...curGroup, obj] }
        }, [])

        const array = Object.entries(groups).map(([key, value]) => {
            return { GroupId: key, questions: value }
        })
        //filter out all rows that are not grouped by groupId
        const filteredArray = array.filter((row: any) => !isNaN(row.GroupId))

        return filteredArray
    }

    const visibleIfParentQuestionIsTouched = (dependencyId: any) => {
        let visible = false
        let answer = ''

        //if answer is true, then show the dependent question
        data.map((row: any) => {
            if (row.Order === dependencyId && row.isTouched) {
                visible = true
                answer = row.Answer
            }
        })

        //Todo: Generates a bug if other questions are dependent on the same question
        //if dependent on answer
        data.map((row: any) => {
            if (dependencyId === row.DependentOnOrder) {
                if (row.DependentOnAnswer && answer !== row.DependentOnAnswer) {
                    visible = false
                    //alert('|' + row.DependentOnAnswer + '| ' + answer)
                }
            }
        })

        return visible
    }
    //special case for textfield dependent on answer in dropdown (DSA -> Organisationsnamn). If inactive then hide textfield in preview
    const filterOutDependentOnAnswer = (questionObject: any) => {
        let filterOut = true
        if (questionObject.DependentOnAnswer) {
            questionObject.DependentOnAnswer &&
            questionObject.DependentOnOrder &&
            questionObject.DependentOnAnswer ===
                data.find(
                    (question: any) =>
                        question.Order === questionObject.DependentOnOrder &&
                        question.CategoryOrder === questionObject.CategoryOrder
                ).Answer
                ? (filterOut = true)
                : (filterOut = false)
        }

        return filterOut
    }

    const showIfChildsParentIsTouchedOrNoDependencyExists = (category: any, questions: any) => {
        let visible = true
        //check if any of the questions has a dependency that is touched

        questions.map((question: any) => {
            if (question.Dependent) {
                data.map((row: any) => {
                    if (category !== row.Category) {
                        if (row.Order === question.DependentOnOrder && !row.isTouched) {
                            visible = false
                        }
                    }
                })
            }
        })

        return visible
    }

    const formatedData = showConfigure ? sortQuestionsByCategory(data) : sortQuestionsBySteps(data)

    let isRenderedCheckboxesInCheckboxPreview = 0
    let tabIndex = 0

    return (
        <>
            {formatedData.map((row: any, index: number) => {
                return (
                    <>
                        {showIfChildsParentIsTouchedOrNoDependencyExists(row.category, row.questions) && (
                            <>
                                <fieldset>
                                    {index === 0 && headline && <h2 style={{ width: '100%' }}>{headline}</h2>}
                                    <h3 style={{ width: '100%' }}>{row.category}</h3>
                                    {showPreview && (
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                                marginBottom: '12px',
                                            }}
                                        >
                                            <h3 style={{ width: '100%' }}>
                                                {/* copilot: give me code for showing preview headline inside arraySteps */}
                                                {arraySteps[row.step - 1].PreviewHeadline}
                                            </h3>
                                            <EditPreviewLink
                                                changeStepHandler={changeStepHandler}
                                                row={row}
                                                arraySteps={arraySteps}
                                                showLinkOnMobile={true}
                                            />
                                            {/* <a
                                                className="editPreview"
                                                onClick={() => {
                                                    changeStepHandler(arraySteps[row.step - 1].Step)
                                                    //alert('changeStepHandler: ' + arraySteps[row.step - 1].Step)
                                                }}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    viewBox="0 0 16 16"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M10.0001 3.99996L12.0001 5.99996M8.66675 13.3333H14.0001M3.33341 10.6666L2.66675 13.3333L5.33341 12.6666L13.0574 4.94263C13.3074 4.69259 13.4478 4.35351 13.4478 3.99996C13.4478 3.64641 13.3074 3.30733 13.0574 3.05729L12.9427 2.94263C12.6927 2.69267 12.3536 2.55225 12.0001 2.55225C11.6465 2.55225 11.3075 2.69267 11.0574 2.94263L3.33341 10.6666Z"
                                                        stroke="#6E3282"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                </svg>
                                                Redigera
                                            </a> */}
                                        </div>
                                    )}
                                    {row.questions.map((question: any, index: number) => {
                                        //set reference to first error. Moving user to first question with errors. See useEffect-> scrollTo here above.
                                        let errorReference = false

                                        errors &&
                                            errors.length > 0 &&
                                            errors[0].Id === question.Id &&
                                            (errorReference = true)
                                        //&& console.log('errors[0].Question', errors[0].Question)

                                        //special case for checkboxes in checkbox in preview when they are grouped by groupid
                                        question.QuestionType === 'CheckboxesInCheckbox' &&
                                            isRenderedCheckboxesInCheckboxPreview++

                                        return (
                                            <>
                                                <span {...(errorReference && { ref: errorRef })} key={index}></span>

                                                {question.QuestionType === 'Dropdown' && (
                                                    <Dropdown
                                                        questionObject={question}
                                                        isTouched={isTouched}
                                                        visible={
                                                            question.Dependent
                                                                ? visibleIfParentQuestionIsTouched(
                                                                      question.DependentOnOrder
                                                                  )
                                                                : true
                                                        }
                                                        showErrors={showErrors}
                                                        showPreview={showPreview}
                                                        showConfigure={showConfigure}
                                                        tabIndex={tabIndex++}
                                                    />
                                                )}
                                                {question.QuestionType === 'CustomDropdown' && (
                                                    <CustomDropdown
                                                        questionObject={question}
                                                        isTouched={isTouched}
                                                        visible={
                                                            question.Dependent
                                                                ? visibleIfParentQuestionIsTouched(
                                                                      question.DependentOnOrder
                                                                  )
                                                                : true
                                                        }
                                                        showErrors={showErrors}
                                                        showPreview={showPreview}
                                                        showConfigure={showConfigure}
                                                        tabIndex={tabIndex++}
                                                    />
                                                )}
                                                {question.QuestionType === 'TextArea' && (
                                                    //<InputTextarea
                                                    <TextArea
                                                        questionObject={question}
                                                        isTouched={isTouched}
                                                        visible={
                                                            question.Dependent
                                                                ? visibleIfParentQuestionIsTouched(
                                                                      question.DependentOnOrder
                                                                  )
                                                                : true
                                                        }
                                                        showErrors={showErrors}
                                                        showPreview={showPreview}
                                                        showConfigure={showConfigure}
                                                        tabIndex={tabIndex++}
                                                    />
                                                )}
                                                {question.QuestionType === 'TextField' && (
                                                    //<InputText
                                                    <TextField
                                                        questionObject={question}
                                                        isTouched={isTouched}
                                                        visible={
                                                            question.Dependent
                                                                ? visibleIfParentQuestionIsTouched(
                                                                      question.DependentOnOrder
                                                                  )
                                                                : true
                                                        }
                                                        showErrors={showErrors}
                                                        showPreview={showPreview}
                                                        showConfigure={showConfigure}
                                                        tabIndex={tabIndex++}
                                                    />
                                                )}
                                                {question.QuestionType === 'Checkbox' && (
                                                    <Checkbox
                                                        questionObject={question}
                                                        isTouched={isTouched}
                                                        visible={
                                                            question.Dependent
                                                                ? visibleIfParentQuestionIsTouched(
                                                                      question.DependentOnOrder
                                                                  )
                                                                : true
                                                        }
                                                        showErrors={showErrors}
                                                        showPreview={showPreview}
                                                        showConfigure={showConfigure}
                                                        tabIndex={tabIndex++}
                                                    />
                                                )}
                                                {question.QuestionType === 'Radio' && (
                                                    <Radio
                                                        questionObject={question}
                                                        isTouched={isTouched}
                                                        visible={
                                                            question.Dependent
                                                                ? visibleIfParentQuestionIsTouched(
                                                                      question.DependentOnOrder
                                                                  )
                                                                : true
                                                        }
                                                        showErrors={showErrors}
                                                        showPreview={showPreview}
                                                        showConfigure={showConfigure}
                                                        tabIndex={tabIndex++}
                                                    />
                                                )}
                                                {question.QuestionType === 'AddMoreFields' && (
                                                    <AddMoreFields
                                                        questionObject={question}
                                                        isTouched={isTouched}
                                                        visible={
                                                            question.Dependent
                                                                ? visibleIfParentQuestionIsTouched(
                                                                      question.DependentOnOrder
                                                                  )
                                                                : true
                                                        }
                                                        showErrors={showErrors}
                                                        showPreview={showPreview}
                                                        showConfigure={showConfigure}
                                                        tabIndex={tabIndex++}
                                                    />
                                                )}
                                                {question.QuestionType === 'DatePicker' && (
                                                    <DatePicker
                                                        questionObject={question}
                                                        isTouched={isTouched}
                                                        visible={
                                                            question.Dependent
                                                                ? visibleIfParentQuestionIsTouched(
                                                                      question.DependentOnOrder
                                                                  )
                                                                : true
                                                        }
                                                        activatedLanguage={activatedLanguage}
                                                        showErrors={showErrors}
                                                        showPreview={showPreview}
                                                        showConfigure={showConfigure}
                                                        tabIndex={tabIndex++}
                                                    />
                                                )}
                                                {question.QuestionType === 'AddFiles' && (
                                                    <AddFiles
                                                        questionObject={question}
                                                        isTouched={isTouched}
                                                        visible={
                                                            question.Dependent
                                                                ? visibleIfParentQuestionIsTouched(
                                                                      question.DependentOnOrder
                                                                  )
                                                                : true
                                                        }
                                                        activatedLanguage={activatedLanguage}
                                                        showErrors={showErrors}
                                                        showPreview={showPreview}
                                                        showConfigure={showConfigure}
                                                        tabIndex={tabIndex++}
                                                    />
                                                )}
                                                {question.QuestionType === 'CheckboxesInCheckbox' && !showPreview && (
                                                    <>
                                                        {!showPreview && question.CategoryDescription && (
                                                            <p style={{ marginBottom: '12px' }}>
                                                                {question.CategoryDescription}
                                                                {question.CategoryMandatory && (
                                                                    <span className="mandatoryAsterisk">&nbsp;*</span>
                                                                )}
                                                            </p>
                                                        )}

                                                        <CheckboxesInCheckbox
                                                            questionObject={question}
                                                            isTouched={isTouched}
                                                            visible={
                                                                question.Dependent
                                                                    ? visibleIfParentQuestionIsTouched(
                                                                          row.DependentOnOrder
                                                                      )
                                                                    : true
                                                            }
                                                            activatedLanguage={activatedLanguage}
                                                            showErrors={showErrors}
                                                            showPreview={showPreview}
                                                            showConfigure={showConfigure}
                                                            groupsArray={[]}
                                                            tabIndex={tabIndex++}
                                                        />
                                                    </>
                                                )}
                                                {/* special case for checkboxes in checkbox in preview when they are grouped */}

                                                {question.QuestionType === 'CheckboxesInCheckbox' &&
                                                    showPreview &&
                                                    isRenderedCheckboxesInCheckboxPreview === 1 && (
                                                        <>
                                                            <CheckboxesInCheckbox
                                                                questionObject={question}
                                                                isTouched={isTouched}
                                                                visible={
                                                                    question.Dependent
                                                                        ? visibleIfParentQuestionIsTouched(
                                                                              row.DependentOnOrder
                                                                          )
                                                                        : true
                                                                }
                                                                activatedLanguage={activatedLanguage}
                                                                showErrors={showErrors}
                                                                showPreview={showPreview}
                                                                showConfigure={showConfigure}
                                                                //special case for checkboxes in checkbox in preview when they are grouped
                                                                groupsArray={sortQuestionsByGroups(data)}
                                                                tabIndex={tabIndex++}
                                                            />
                                                        </>
                                                    )}

                                                {question.QuestionType === 'HajkMapArea' && (
                                                    <HajkMapArea
                                                        questionObject={question}
                                                        isTouched={isTouched}
                                                        visible={
                                                            question.Dependent
                                                                ? visibleIfParentQuestionIsTouched(
                                                                      question.DependentOnOrder
                                                                  )
                                                                : true
                                                        }
                                                        showErrors={showErrors}
                                                        showPreview={showPreview}
                                                        showConfigure={showConfigure}
                                                        tabIndex={tabIndex++}
                                                        linkToMap={'https://utv-etjanst.pts.ad/exempel-karta.png'}
                                                    />
                                                )}

                                                {!question.languageSupportExists && (
                                                    <div style={{ color: '#cccccc' }}>
                                                        No language support in content config file!!
                                                    </div>
                                                )}
                                                {!question.languageSupportExists &&
                                                    console.log(
                                                        'No language support in content config file!! Question Id: ' +
                                                            question.Id
                                                    )}
                                            </>
                                        )
                                    })}
                                </fieldset>
                            </>
                        )}
                        <div
                            style={{
                                display: 'none',
                                position: 'sticky',
                                bottom: '0px',
                                backgroundColor: 'green',
                                border: '2px solid #4CAF50',
                            }}
                        >
                            {/* inactivated timer and move to next error */}
                            {/* <CustomTimer
                                newObject={currentError}
                                stopTime={nextCountDown}
                                funcAlarm={moveToNextError}
                            /> */}
                        </div>
                    </>
                )
            })}
            <span ref={forwardButton}></span>
            {showPreview && <PrincipleOfPublicity activatedLanguage={activatedLanguage} />}

            {endMessage && endMessage.Headline && (
                <>
                    <h2 style={{ width: '100%', marginTop: '24px' }}>{endMessage.Headline}</h2>
                    <p style={{ width: '100%' }}>{endMessage.Body}</p>
                </>
            )}
        </>
    )
}

export default UserInputs
