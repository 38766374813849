import { Link, createBrowserRouter } from 'react-router-dom'
import RootLayout from '../../pages/root/Root'
import ErrorPage from '../../pages/root/Error'

import Example from '../../pages/Example'

import BackOffice from '../../pages/backOffice/List'
import Details from '../../pages/backOffice/Details'

export const router = createBrowserRouter([
    {
        path: '/',
        element: <RootLayout />,
        errorElement: <ErrorPage />,

        children: [{ index: true, element: <Example /> }],
    },

    {
        path: '/backoffice',
        element: <RootLayout />,
        errorElement: <ErrorPage />,
        handle: {
            crumb: () => <Link to="/">Startsidan</Link>,
        },
        children: [
            { index: true, element: <BackOffice /> },
            {
                path: 'details',
                element: <Details />,
                handle: {
                    crumb: () => <Link to="Step1">Steg 1</Link>,
                },
            },
        ],
    },

    // {
    //     path: '/DSA',
    //     element: <RootLayout />,
    //     errorElement: <ErrorPage />,

    //     children: [{ index: true, element: <DSA /> }],
    // },
])
