/*state management with redux*/
import { useAppSelector } from '../../app/hooks'
import { selectMainHeadline } from '../../features/languageSupport/languageSupport.Slice'
import { ILanguage } from '../../features/languageSupport/languageSupport.Types'
/*-----------------------------*/

import { Col, Container, Row } from 'react-bootstrap'
import { Outlet, useLocation } from 'react-router-dom'

//import Header from '../../components/Header/Header'
import { Header, Footer, Modal } from 'ui-components-react-package-45ds546dksw34'

import './styles.css'
import { useEffect, useState } from 'react'
//import Footer from '../../components/Footer/Footer'

const RootLayout = () => {
    const location = useLocation()
    /*state management with redux*/
    //const MainHeadline: string = useAppSelector(selectMainHeadline)
    /*-----------------------------*/

    const [modalContent, setModalContent] = useState({})
    const [showModal, setShowModal] = useState(false)

    const handleModalOpen = (content: any) => {
        //console.log('Modal Content', content)
        setModalContent(content)
        setShowModal(true)
        content.Close && content.Close === true && setShowModal(false)
    }
    const handleModalClose = () => {
        setShowModal(false)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location.pathname])

    return (
        <>
            <Modal content={modalContent} showModal={showModal} handleModalClose={handleModalClose} />
            <div className="d-flex flex-column h-100" style={{ minHeight: '100vh' }}>
                <Header />
                <Container fluid="sm" style={{ maxWidth: '860px' }}>
                    <Row className="containerRow">
                        <Col>
                            <main>
                                <Outlet context={handleModalOpen} />
                            </main>
                        </Col>
                    </Row>
                </Container>

                <Footer />
            </div>
        </>
    )
}

export default RootLayout
