import React, { useEffect, useState } from 'react'
import { GetHandler } from '../../app/ApiHandler'
import { ListWithPills } from 'ui-components-react-package-45ds546dksw34'
//import ListWithPills from '../../components/ListWithPills'

function List({ headline = 'Inkomna ärenden' }) {
    const apiController = 'FrontEndSavedDataJsons'
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [config, setConfig] = useState<any>({})

    useEffect(() => {
        fetch('/config.json')
            .then(response => response.json())
            .then(data => setConfig(data))
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                const AccessHeader = config.AccessHeader
                const data = await GetHandler({ AccessHeader, apiController })
                setData(data)
                setLoading(false)
            } catch (error: any) {
                setError(error)
                setLoading(false)
            }
        }
        fetchData()
    }, [])

    return (
        <div>
            <h3>{headline}</h3>
            {loading && 'Loading...'}
            {error && 'Error'}

            <ListWithPills list={data} />
        </div>
    )
}

export default List
