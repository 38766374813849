import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { ILanguageSupport } from './languageSupport.Types'
import { LanguageSupport } from './languageSupport.InitialState'
//import myJson from './content.dsa.languageSupport.json'
//import { LanguageSupport } from './old_languageSupport.Config innovationstavlingen'

import { GetContentConfigFile } from '../../app/ConfigFilesHandler'

export const initialState: ILanguageSupport = {
    supportedLanguage: LanguageSupport.supportedLanguage,
    activatedLanguage: LanguageSupport.activatedLanguage,
    //supportedLanguage: myJson.LanguageSupport.supportedLanguage,
    //activatedLanguage: myJson.LanguageSupport.activatedLanguage,
    languageSupportLoaded: false,

    status: 'idle',
}

export const contentConfigFileToGlobalStateAsync = createAsyncThunk(
    'languageSupport/GetContentConfigFileAsync',
    async (props: any) => {
        const { url, fileName } = props
        console.log('2. contentConfigFileToGlobalStateAsync: ' + url + ' fileName: ' + fileName)
        const response = await GetContentConfigFile({ url, fileName })
        //alert('response: ' + JSON.stringify(response))
        return response.data
    }
)

export const LanguageSlice = createSlice({
    name: 'LanguageSupport',
    initialState,
    reducers: {
        SetLanguage: (state, action: PayloadAction<any>) => {
            state.supportedLanguage = action.payload
        },
    },

    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: builder => {
        builder

            //get content from config file
            .addCase(contentConfigFileToGlobalStateAsync.pending, state => {
                state.status = 'loading'
            })
            .addCase(contentConfigFileToGlobalStateAsync.fulfilled, (state, action: PayloadAction<any>) => {
                const { LanguageSupport } = action.payload
                state.status = 'idle'
                state.supportedLanguage = LanguageSupport.supportedLanguage
                state.activatedLanguage = LanguageSupport.activatedLanguage
                state.languageSupportLoaded = true
            })
            .addCase(contentConfigFileToGlobalStateAsync.rejected, (state, action: PayloadAction<any>) => {
                state.status = 'failed'
            })
    },
})

export const { SetLanguage } = LanguageSlice.actions

export const selectLanguageSupportOverAll = (state: RootState) => {
    //console.log('languageSupport', state.languageSupport)
    const supports = state.languageSupport.supportedLanguage
    let blob: any = {}
    supports.map((language: any) => {
        if (language.language == state.languageSupport.activatedLanguage) {
            blob = language
        }
    })
    return blob
}

export const selectLanguageSupportForFeature = (state: RootState, page: string) => {
    //console.log('languageSupport', state.languageSupport)
    const blob = selectLanguageSupportOverAll(state)
    let pageBlob: any = {}
    blob.supportedPage.map((supported: any) => {
        if (supported.page == page) {
            pageBlob = supported
        }
    })
    return pageBlob
}

export const selectMainHeadline = (state: RootState) => {
    const blob = selectLanguageSupportOverAll(state)
    return blob.supportedOverall.MainHeadLine
}

export const selectStatusLanguageSupportContent = (state: RootState) => state.languageSupport.status

export const selectActivatedLanguage = (state: RootState) => state.languageSupport.activatedLanguage

export const selectLanguageSupportState = (state: RootState) => state.languageSupport

export default LanguageSlice.reducer
