import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { IExampleState } from '../../app/types'
import { EditHandler, SubmitHandler, GetTokenHandler } from '../../app/ApiHandler'
import { ExampleConfig } from './questions.InitialState'
//import myJson from './content.dsa.questions.json'
import { GetQuestionsConfigFile } from '../../app/ConfigFilesHandler'

export const initialState: IExampleState = {
    data: ExampleConfig,
    stepper: [{ Step: 0, StepperText: 'InitText' }],
    //data: myJson.data,
    //stepper: myJson.stepper,
    status: 'idle',
    confirmed: false,
    caseClosed: false,
    editOngoing: false,
    editEnabled: false,
    saved: 'not yet!',
    questionsLoaded: false,
}

// export const getTokenApiAsync = createAsyncThunk('DSA/getTokenApiAsync', async (AccessHeader: any) => {
//     const response = await GetTokenHandler(AccessHeader)
//     //The value we return becomes the `fulfilled` action payload
//     //console.log('getTokenApiAsync', response)
//     return response
// })

// export const postPreviewToApiAsync = createAsyncThunk(
//     'DSA/postPreviewToApi',
//     async ({ data, confirmed, token, postToServerUrl, AccessHeader }: any) => {
//         //alert('postPreviewToApiAsync')
//         //console.log('postPreviewToApiAsync', data, token)
//         const response = await SubmitHandler({ data, confirmed, token, postToServerUrl, AccessHeader })
//         //The value we return becomes the `fulfilled` action payload
//         if (response.data === 'success') {
//             return response
//         }
//         if (response.data === 'error') {
//             return response.data
//         }
//     }
// )

export const questionsConfigFileToGlobalStateAsync = createAsyncThunk(
    'questions/GetQuestionsConfigFileAsync',
    async (props: any) => {
        const { url, fileName } = props
        console.log('2. questionsConfigFileToGlobalStateAsync' + url + ' ' + fileName)

        const response = await GetQuestionsConfigFile({ url, fileName })
        //alert('response: ' + JSON.stringify(response))
        return response.data
    }
)

export const createNewCase = createAsyncThunk(
    'e-Service/Create New Case',
    async ({ data, confirmed, token, postToServerUrl, AccessHeader }: any) => {
        //alert('createNewCase')

        const response = await SubmitHandler({ data, confirmed, token, postToServerUrl, AccessHeader })
        //alert('response: ' + JSON.stringify(response))
        //The value we return becomes the `fulfilled` action payload
        if (response.data === 'success') {
            return response
        }
        if (response.data === 'error') {
            return response
        }
    }
)

export const editExistingCase = createAsyncThunk(
    'e-Service/Edit Existing Case',
    async ({ appId, data, confirmed, token, postToServerUrl, AccessHeader }: any) => {
        const response = await EditHandler({ appId, data, confirmed, token, postToServerUrl, AccessHeader })

        //The value we return becomes the `fulfilled` action payload
        if (response.data === 'success') {
            return response
        }
        if (response.data === 'error') {
            return response
        }
    }
)

export const inputComponentLibrarySlice = createSlice({
    name: 'ICL',
    initialState,
    reducers: {
        AddQuestionsFromLanguageSupport: (state, action: PayloadAction<any>) => {
            state.data = [...action.payload]
        },
        AddAnswer: (state, action: PayloadAction<any>) => {
            state.data = [
                ...state.data.map((qObject: any, index: number) => {
                    //Answer is set when matching row is found AND answer is not same as question (sometimes occurs as first option in a select)
                    if (qObject.Id == action.payload.Id && qObject.Question != action.payload.Answer) {
                        qObject.Answer = action.payload.Answer
                        //qObject.Question = action.payload.Question
                        qObject.isTouched = true

                        return qObject
                    }
                    return qObject
                }),
            ]
        },
        AddMoreFields: (state, action: PayloadAction<any>) => {
            state.data = [
                ...state.data.map((qObject: any, index: number) => {
                    //Answer is set when matching row is found AND answer is not same as question (sometimes occurs as first option in a select)
                    if (qObject.Id == action.payload.Id && qObject.Question != action.payload.Answer) {
                        qObject.MoreFields = action.payload.MoreFields
                        //qObject.Question = action.payload.Question
                        qObject.isTouched = true

                        return qObject
                    }
                    return qObject
                }),
            ]
        },
        AddFiles: (state, action: PayloadAction<any>) => {
            state.data = [
                ...state.data.map((qObject: any, index: number) => {
                    //Answer is set when matching row is found AND answer is not same as question (sometimes occurs as first option in a select)
                    if (qObject.Id == action.payload.Id && qObject.Question != action.payload.Answer) {
                        qObject.Files = action.payload.Files
                        //qObject.Question = action.payload.Question
                        qObject.isTouched = true

                        return qObject
                    }
                    return qObject
                }),
            ]
        },

        AddCheckboxesInCheckbox: (state, action: PayloadAction<any>) => {
            state.data = [
                ...state.data.map((qObject: any, index: number) => {
                    //Answer is set when matching row is found AND answer is not same as question (sometimes occurs as first option in a select)
                    if (qObject.Id == action.payload.Id && qObject.Question != action.payload.Answer) {
                        qObject.questionArray = action.payload.questionArray

                        //qObject.Question = action.payload.Question
                        //qObject.isTouched = true

                        return qObject
                    }
                    return qObject
                }),
            ]
        },
        AddError: (state, action: PayloadAction<any>) => {
            state.data = [
                ...state.data.map((qObject: any, index: number) => {
                    //console.log('AddError', JSON.stringify(action.payload))
                    if (qObject.Id == action.payload.Id) {
                        qObject.error = true

                        return qObject
                    }
                    return qObject
                }),
            ]
        },
        RemoveErrors: (state, action: PayloadAction<any>) => {
            state.data = [
                ...state.data.map((qObject: any, index: number) => {
                    qObject.error = false
                    return qObject
                }),
            ]
        },
        RemoveAll: state => {
            state.data = []
        },
        EditEnabled: (state, action: PayloadAction<any>) => {
            state.editEnabled = action.payload
        },
        EditOngoing: (state, action: PayloadAction<any>) => {
            state.editOngoing = action.payload
        },
        Confirmed: (state, action: PayloadAction<any>) => {
            state.confirmed = action.payload
        },
        CaseClosed: (state, action: PayloadAction<any>) => {
            state.caseClosed = action.payload
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: builder => {
        builder

            //postPreviewToApiAsync
            .addCase(createNewCase.pending, state => {
                state.status = 'loading'
                state.saved = ''
            })
            .addCase(createNewCase.fulfilled, (state, action: PayloadAction<any>) => {
                const { data } = action.payload
                state.status = 'idle'
                state.saved = data
            })
            .addCase(createNewCase.rejected, (state, action: PayloadAction<any>) => {
                state.status = 'failed'
                state.saved = action.payload
            })
            //getTokenApiAsync
            .addCase(editExistingCase.pending, state => {
                state.status = 'loading'
                state.saved = ''
            })
            .addCase(editExistingCase.fulfilled, (state, action: PayloadAction<any>) => {
                const { data } = action.payload
                state.status = 'idle'
                state.saved = data
            })
            .addCase(editExistingCase.rejected, (state, action: PayloadAction<any>) => {
                state.status = 'failed'
                state.saved = action.payload
            })
            //get content from config file
            .addCase(questionsConfigFileToGlobalStateAsync.pending, state => {
                state.status = 'loading'
            })
            .addCase(questionsConfigFileToGlobalStateAsync.fulfilled, (state, action: PayloadAction<any>) => {
                const { data, stepper } = action.payload
                state.status = 'idle'
                state.stepper = stepper
                state.data = data
                state.questionsLoaded = true
            })
            .addCase(questionsConfigFileToGlobalStateAsync.rejected, (state, action: PayloadAction<any>) => {
                state.status = 'failed'
            })
    },
})

export const {
    AddAnswer,
    AddMoreFields,
    AddFiles,
    AddCheckboxesInCheckbox,
    AddError,
    RemoveErrors,
    RemoveAll,
    EditEnabled,
    EditOngoing,
    Confirmed,
    CaseClosed,
    AddQuestionsFromLanguageSupport,
} = inputComponentLibrarySlice.actions

export const selectExampleState = (state: RootState) => state.Example

export const selectStepQuestions = (state: RootState, step: number, totalSteps: number) => {
    if (step == totalSteps) {
        return state.Example.data
    } else {
        return state.Example.data.filter((qObject: any, index: number) => {
            return qObject.Step == step
        })
    }
}

export const selectQuestionsFromLanguageSupport = (state: RootState, activatedLanguage: string) => {
    return state.Example.data.map((qObject: any, index: number) => {
        let languageSupport = qObject.languageSupport.find((lang: any) => lang.language == activatedLanguage)

        let languageSupportExists = true
        if (!languageSupport) {
            languageSupport = {}
            languageSupportExists = false
        }

        return {
            ...qObject,
            Question: languageSupport.Question,
            Options: languageSupport.Options,
            errorSpecificMessenge: languageSupport.errorSpecificMessenge,
            About: languageSupport.About,
            MoreInfo: languageSupport.MoreInfo,
            MoreFields: qObject.MoreFields,
            languageSupportExists: languageSupportExists,
        }
    })
}

export default inputComponentLibrarySlice.reducer
