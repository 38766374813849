import FeatureInnovationAnmalan from '../../features/Example'
import { TransitionForPages } from 'ui-components-react-package-45ds546dksw34'

const pageDSA = () => {
    return (
        <>
            <TransitionForPages>
                <FeatureInnovationAnmalan />
            </TransitionForPages>
        </>
    )
}

export default pageDSA
